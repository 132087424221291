import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { AuthService } from '@core/services/auth/auth.service'
import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs'
import { TokenRefresh, UsersService } from '@core/api'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly usersService: UsersService,
  ) {}

  private isRefreshing = false
  private refreshTokenSubject: BehaviorSubject<null | string> = new BehaviorSubject<null | string>(null)

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = req
    const token = this.authService.accessToken()
    if (token != null) {
      authReq = this.addTokenHeader(req, token)
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && !authReq.url.includes('token-refresh') && error.status === 401) {
          return this.handle401Error(authReq, next)
        }

        return throwError(() => error)
      }),
    )
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true
      this.refreshTokenSubject.next(null)

      const token = this.authService.refreshToken()

      if (token) {
        return this.usersService
          .usersTokenRefreshCreate({
            tokenRefreshRequest: { refresh: token },
          })
          .pipe(
            switchMap((resp: TokenRefresh) => {
              this.isRefreshing = false
              this.authService.setToken(resp.access, null)

              if (!resp.access) {
                return throwError(() => new Error(`Cannot fetch access token`))
              }

              this.refreshTokenSubject.next(resp.access)
              return next.handle(this.addTokenHeader(request, resp.access))
            }),
            catchError((err) => {
              this.isRefreshing = false
              this.authService.signOut()
              return throwError(() => err)
            }),
          )
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token))),
    )
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private addTokenHeader(request: HttpRequest<any>, token: string | null) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token!}`) })
  }
}
