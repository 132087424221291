import { homeGuard } from '@core/guards/home.guard'
import { GtOutletComponent } from '@pages/gt-outlet/gt-outlet.component'
import { WsOutletComponent } from '@pages/ws-outlet/ws-outlet.component'

export const routes = [
  {
    path: 'gt',
    component: GtOutletComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@pages/gt-outlet/gt-outlet.route').then((m) => [...m.registerRoutes, ...m.routes]),
      },
    ],
  },
  {
    path: 'ws',
    component: WsOutletComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@pages/ws-outlet/ws-outlet.route').then((m) => [...m.registerRoutes, ...m.routes]),
      },
    ],
  },
  {
    path: '',
    loadComponent: () => import('@pages/home-page/home-page.component').then((m) => m.HomePageComponent),
    canActivate: [homeGuard],
  },
  { path: '**', redirectTo: '/' },
]
