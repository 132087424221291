import { Injectable } from '@angular/core'
import liff from '@line/liff'
import { TokenResponse, UsersService } from '@core/api'
import { Observable, of, switchMap, throwError } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private usersService: UsersService) {}

  public initApp(forceToken: string = ''): Observable<null> {
    if (this.accessToken()) {
      return of(null)
    }

    let liffAccessToken: string | null = forceToken

    if (!forceToken) {
      liffAccessToken = liff.getAccessToken()
    }

    if (liffAccessToken === null) {
      return throwError(() => new Error('Cannot retrieve Line access token'))
    }

    return this.authUser(liffAccessToken)
  }

  public signOut() {
    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('refresh_token')
    window.location.reload()
  }

  private authUser(liffAccessToken: string): Observable<null> {
    return this.usersService.usersLoginCreate({ loginRequest: { accessToken: liffAccessToken } }).pipe(
      switchMap((resp: TokenResponse) => {
        const { access, refresh } = resp
        this.setToken(access, refresh)
        return of(null)
      }),
    )
  }

  setToken(access: string | null, refresh: string | null) {
    if (access) {
      sessionStorage.setItem('access_token', access)
    }
    if (refresh) {
      sessionStorage.setItem('refresh_token', refresh)
    }
  }

  accessToken() {
    return sessionStorage.getItem('access_token')
  }

  refreshToken() {
    return sessionStorage.getItem('refresh_token')
  }
}
