import { Component, signal } from '@angular/core'
import { ButtonComponent } from '@shared/components/v1/button/button.component'
import { CheckboxComponent } from '@shared/components/v1/checkbox/checkbox.component'
import { ModalComponent } from '@shared/components/v1/modal/modal.component'
import { Router } from '@angular/router'

@Component({
  selector: 'app-modal-product-update-from-master',
  standalone: true,
  imports: [ButtonComponent, CheckboxComponent, ModalComponent],
  templateUrl: './modal-product-update-from-master.component.html',
})
export class ModalProductUpdateFromMasterComponent {
  show = signal(true)
  dontShowAgain = signal(false)

  constructor(private readonly router: Router) {}

  closeShow() {
    this.show.set(false)
    localStorage.setItem('ignoreUpdateFromMaster', this.dontShowAgain() ? 'true' : 'false')
  }

  goToProductUpdate() {
    this.closeShow()
    this.router.navigate(['/ws/packages/update-from-master']).then()
  }
}
