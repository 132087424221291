import { Observable } from 'rxjs'
import { AuthService } from '@core/services/auth/auth.service'
import { EnvConfigurationService } from './services/auth/env-config.service'
import { CookieService } from 'ngx-cookie-service'

export default function initializeAppFactory(
  authService: AuthService,
  envConfigurationService: EnvConfigurationService,
  cookieService: CookieService,
): () => Observable<null> {
  let forceToken = ''

  if (envConfigurationService.getConfig()?.DISABLE_LINE_LIFF) {
    // get token from browser cookie, this is mainly for e2e test to bypass Line LIFF
    forceToken = cookieService.get('FORCE_TOKEN')
  }

  return () => authService.initApp(forceToken)
}
