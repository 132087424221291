<app-modal [visible]="show()" [showHeader]="false" [contentRef]="content" [showFooter]="false">
  <ng-template #content>
    <div class="flex flex-col items-center justify-center gap-2 mb-4">
      <img src="/assets/images/update.svg" alt="close" />
      <p class="font-bold text-lg">แจ้งมีการเปลี่ยนแปลงข้อมูลสินค้า</p>
      <div class="text-center text-base">
        <p>สินค้าบางชิ้นมีการเปลี่ยนแปลงข้อมูล</p>
        <p>เนื่องจากสินค้าบนฐานข้อมูลมีการอัปเดต</p>
      </div>
    </div>
    <app-checkbox (valueChange)="dontShowAgain.set($event)"> ไม่ต้องแสดงข้อความนี้อีก</app-checkbox>

    <app-button
      class="w-full mt-1"
      label="ดูสินค้าที่มีการเปลี่ยนแปลง"
      buttonType="primary"
      buttonSize="l-full"
      data-test-id="modal-confirm-button"
      (clickButton)="goToProductUpdate()"
    ></app-button>
    <app-button
      class="w-full mt-1"
      label="ปิด"
      buttonType="outline"
      buttonSize="l-full"
      (clickButton)="closeShow()"
    ></app-button>
  </ng-template>
</app-modal>
