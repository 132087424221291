import { AppComponent } from './app/app.component'
import { appConfig } from './app/app.config'
import { environment } from './environments/environment'
import liff from '@line/liff'
import { bootstrapApplication } from '@angular/platform-browser'
import { IConfiguration } from './common'
import { enableProdMode } from '@angular/core'
import { APP_CONFIG } from './app/app-instance'

const path = '/assets/config.json'
import * as Sentry from '@sentry/angular-ivy'

fetch(path)
  .then((config: Response) => config.json())
  .then(async (config: IConfiguration) => {
    const version = await fetch('/assets/git-info.json')
    const versionJSON = await version.json()

    Sentry.init({
      release: `ruammitr-frontent@${versionJSON.commitTag === '-' ? versionJSON.shortSHA : versionJSON.commitTag}`,
      dsn: 'https://6a638a5a7afc93a760efa53b1c53010d@o4506986650271744.ingest.us.sentry.io/4506986651975680',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.2, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/staging.app.ruammitr\.io\/backend/,
        /^https:\/\/jvb2b-ecom-dev.internal.thegang\.tech\/backend/,
        /^https:\/\/app.ruammitr\.io\/backend/,
      ],
      // Session Replay
      replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      enabled: environment.production,
      environment: config.ENVIRONMENT || 'development',
    })

    if (environment.production || !config.IS_LOCALHOST) {
      enableProdMode()
    }

    // override config if environment is set
    if (import.meta.env) {
      config.LIFF_ID = import.meta.env['NG_APP_LINE_LIFF_ID']
      config.BACKEND_URL = import.meta.env['NG_APP_BACKEND_URL']
      config.ANALYTICS_URL = import.meta.env['NG_APP_ANALYTICS_URL']
      config.GOOGLE_MAP_KEY = import.meta.env['NG_APP_GOOGLE_MAP_KEY']
    }

    appConfig.providers.push({ provide: APP_CONFIG, useValue: config })

    if (config.DISABLE_LINE_LIFF) {
      bootstrapApplication(AppComponent, appConfig)
        .then()
        .catch((err) => console.error(err))
      return
    }

    const liffId = config.LIFF_ID

    liff
      .init({
        liffId: liffId,
        withLoginOnExternalBrowser: true,
      })
      .then(() => {
        bootstrapApplication(AppComponent, appConfig)
          .then()
          .catch((err) => {
            Sentry.captureException(err)
            if (err) {
              alert(`Cannot initialize the app. Please notify administrator.\n\n${err}`)
            }
          })
      })
      .catch((err) => {
        Sentry.captureException(err)
        alert('Cannot connect with Line: ' + err)
      })
  })
  .catch((err: Error) => {
    Sentry.captureException(err)
    alert('Cannot fetch server configuration. Please notify administrator.')
    console.error(err)
  })
